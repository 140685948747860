import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Header = ({ page }) => {

  return (
    <div>
      <Row>
        <Col>
          <p className="text-uppercase text-muted mb-1" style={{ fontSize: '0.75rem' }}>
            Gerenciamento
          </p>
          <h4 className="mb-2">
            {page ? page.toUpperCase() : '--'}
          </h4>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
