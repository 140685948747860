import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Table, Pagination, Button } from 'react-bootstrap';

const Results = ({ data, permission }) => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0); // Reset to first page
  };

  const handleClick = (serial) => {
    history.push(`/support/${serial}`);
  };

  const getPaginationItems = () => {
    const items = [];
    for (let i = 0; i < totalPages; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === page}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div>
      <p>
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Equipamentos</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: 15 }} striped bordered hover responsive size='sm'>
            <thead>
              <tr class="table-active">
                <th className="text-center">SERIAL</th>
                <th className="text-center">ENDEREÇO</th>
                <th className="text-center">MODO</th>
                <th className="text-center">PLANO</th>
                <th className="text-center">DIAS PARA ATIVAÇÃO</th>
                <th className="text-center">VERSÃO DE SOFTWARE</th>
                <th className="text-center">AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={item._id}>
                  <td className="text-center pt-2 pb-2">{item?.serialnumber || '--'}</td>
                  <td className="text-center pt-2 pb-2">
                    {item?.address?.formatedAddr
                      ? item.address.formatedAddr.length < 50
                        ? item.address.formatedAddr
                        : `${item.address.formatedAddr.slice(0, 50)}...`
                      : '---'}
                  </td>
                  <td className="text-center pt-2 pb-2">
                    {item?.mode === 'RENTAL' ? 'Locação' : 'Proprietário'}
                  </td>
                  <td className="text-center pt-2 pb-2">{item?.plan || 'NORMAL'}</td>
                  <td className="text-center pt-2 pb-2">
                    {item?.daystoactivate ? parseInt(item.daystoactivate, 16) : '--'}
                  </td>
                  <td className="text-center pt-2 pb-2">{item?.versaosw || '--'}</td>
                  <td className="text-center">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleClick(item.serialnumber)}
                    >
                      Conectar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                className="form-select form-select-sm"
              >
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="mb-0">
            <Pagination.Prev
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 0}
            />
            {getPaginationItems()}
            <Pagination.Next
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= totalPages - 1}
            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
