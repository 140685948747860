import React, { useState, useEffect } from 'react';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Results } from './components';
import Reload from '../../components/Reload/Reload';
import Header from '../../components/Header/Header';
import { Button, Row, Col } from 'react-bootstrap';
import ApiMServer from 'services/ApiMServer';

const User = () => {
  const user = JSON.parse(localStorage.getItem('userData'))
  if (user?.permission?.users?.read === false) {
    window.location.replace('/home')
  }

  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [permission, setPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const getUsers = async () => {
    setSearchData('')
    try {
      let { data } = await ApiMServer.get('/user/all', { headers: { token: getToken() } });
      let usersSort = data.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setUsers(usersSort);
      setTempUsers(usersSort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Erro ao carregar usuários!");
    }
  }

  useEffect(() => {
    setPermission(user?.permission?.users?.write);
    getUsers();
  }, []);

  const getUsersFilter = async () => {
    if (!searchData) {
      alert("Digite um filtro.")
      return
    }
    setLoading(true);
    try {
      let { data } = await ApiMServer.get('/getusersfilter', { params: { filter: searchData }, headers: { token: getToken() } });
      let usersSort = data.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setUsers(usersSort);
      setTempUsers(usersSort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Erro", error)
    }
  }

  const handleSearch = () => {
    if (!searchData.trim()) {
      setTempUsers(users);
      return;
    }

    const usersSearch = users.filter((user) =>
      ['name', 'email', 'cpfcnpj'].some(
        (key) =>
          user?.[key] &&
          user[key].toUpperCase().includes(searchData.toUpperCase())
      )
    );
    setTempUsers(usersSearch);
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Page className="p-3" title="Usuários">
      <Header page={'Usuários'} />

      {loading && <Reload />}

      <Row>
        <Col>
          <SearchBar
            onSearch={handleSearch}
            onSearchData={handleSearchData}
            searchData={searchData}
          />

          <Button
            style={{ width: 250, marginRight: 15 }}
            className="mt-2 mb-2"
            onClick={() => { getUsersFilter() }}
            variant="success"
          >
            Buscar Filtro
          </Button>

          <Button
            style={{ width: 250 }}
            className="mt-2 mb-2"
            onClick={() => { getUsers() }}
            variant="success"
          >
            Buscar Todos
          </Button>
        </Col>
      </Row>

      {tempUsers && (
        <Results
          data={tempUsers}
          permission={permission}
        />
      )}

    </Page>
  );
};

export default User;
