import React, { useState, useCallback } from 'react';
import { Table, Pagination, Button, Card } from 'react-bootstrap';

const Results = ({ data, onRemoveButton, permission }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(Number(event.target.value));
  }, []);

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const getPaginationRange = () => {
    const start = Math.max(page - 2, 0);
    const end = Math.min(page + 2, totalPages - 1);
    let range = [];

    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  };

  return (
    <div>
      <p className="text-muted">
        {data.length} registros encontrados. Página {page + 1} de {totalPages}
      </p>
      <Card>
        <Card.Header>Cooperativas</Card.Header>
        <Card.Body>
          <Table style={{ fontSize: 15 }} striped bordered hover responsive size='sm'>
            <thead>
              <tr>
                <th className="text-center col-sm-3">NOME</th>
                <th className="text-center">CNPJ</th>
                <th className="text-center">ENDEREÇO</th>
                <th className="text-center">P</th>
                <th className="text-center">UNIDADES</th>
                <th className="text-center">EQUIPAMENTOS</th>
                <th className="text-center col-sm-2">AÇÃO</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item) => (
                <tr key={item._id}>
                  <td className="pt-2">{item.name || '--'}</td>
                  <td className="text-center pt-2">{item.cnpj || '--'}</td>
                  <td className="text-center pt-2">
                    {item.address
                      ? item.address.length < 40
                        ? item.address
                        : `${item.address.slice(0, 40)}...`
                      : '--'}
                  </td>
                  <td className="text-center pt-2">{item.chart_p || '--'}</td>
                  <td className="text-center pt-2">{item.units?.length || '--'}</td>
                  <td className="text-center pt-2">{item.equipments?.length || '--'}</td>
                  <td className="text-center">
                    <Button
                      size="sm"
                      className='ms-2'
                      variant="outline-success"
                      href={`/cooperatives/${item._id}`}
                    >
                      Visualizar
                    </Button>
                    <Button
                      size="sm"
                      className='ms-2'
                      variant="outline-danger"
                      onClick={() => permission && onRemoveButton(item._id)}
                      disabled={!permission}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <div>
            <label>
              Linhas por página:{' '}
              <select
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}>
                {[10, 20, 40].map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <Pagination className="custom-pagination" variant="dark">
            <Pagination.Prev
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              aria-label="Página anterior"
            />
            {getPaginationRange().map((index) => (
              <Pagination.Item
                key={index}
                active={index === page}
                onClick={() => handleChangePage(index)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handleChangePage(page + 1)}
              disabled={page >= totalPages - 1}
              aria-label="Próxima página"
            />
          </Pagination>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default Results;
