import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Button
} from '@material-ui/core';
import { Label } from 'semantic-ui-react';
import { SearchLocationInput } from 'components';

import { FloatingLabel, Form } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '30px'
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  formGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
  tags: {
    marginTop: theme.spacing(1),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  dateField: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  label: {
    fontSize: 21
  },
  labelPass: {
    color: 'black',
    fontFamily: 'bold',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: 26
  }
}));

const Form3 = (props) => {
  const {
    className,
    alertMessage,
    unlock,
    fieldChange,
    onSubmit,
    unities,
    permission,
    password,
    setLocation,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <form>
          <label className={classes.label}>Gerar senha</label>
          <Label className={classes.labelPass} disabled={permission === true ? false : true}>
            {password ? password : null}
          </Label>

          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <div className={classes.formGroup}>

                {/* <TextField
                  disabled={permission === true ? false : true}
                  className={classes.textField}
                  aria-readonly="true"
                  autoComplete="off"
                  label="Token"
                  name="token"
                  variant="outlined"
                  onChange={(event) =>
                    fieldChange(event, 'token', event.target.value)
                  }
                /> */}

                <FloatingLabel
                  controlId="floatingToken"
                  label="Token"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Token"
                    name="token"
                    autoComplete="off"
                    disabled={permission === true ? false : true}
                    onChange={(event) => fieldChange(event, 'token', event.target.value)}
                  />
                </FloatingLabel>

                <SearchLocationInput setLocation={setLocation} />
                
                <Button
                  disabled={permission === true ? false : true}
                  className={classes.saveButton}
                  color="primary"
                  onClick={() => onSubmit()}
                  variant="contained">
                  Gerar
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default Form3;
