import React from 'react';
import dateformat from 'utils/dateformat';
import {
  Row,
  Col,
  Card,
  Form,
  Table,
  Button,
  FloatingLabel,
} from 'react-bootstrap'

const Forms = (props) => {
  const {
    user,
    permission,
    fieldChanged,
    cpfChanged,
    phoneChanged,
    saveUser,
    setUser,
    setValueForm,
    setValueFormSenhas
  } = props;

  const masterUser = JSON.parse(localStorage.getItem('userData'));

  return (
    <Card className='mb-3 mt-3 p-3'>
      <Row className="g-2">
        <Col md>
          <FloatingLabel controlId="floatingInput" label="Nome" className="mb-3">
            <Form.Control
              type="text"
              name="nome"
              placeholder="Nome"
              value={user?.name || ''}
              onChange={(event) =>
                fieldChanged(event, 'name', event.target.value)
              }
              disabled={!masterUser?.permission?.users?.write}
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel controlId="floatingInput" label="E-mail" className="mb-3">
            <Form.Control
              type="email"
              name="E-mail"
              placeholder="E-mail"
              value={user?.email || ''}
              disabled
            />
          </FloatingLabel>
        </Col>
      </Row>

      <Row className="g-2">
        <Col md>
          <FloatingLabel controlId="floatingInput" label="CPF" className="mb-3">
            <Form.Control
              type="cpf"
              name="cpf"
              placeholder="CPF"
              value={user?.cpfcnpj || ''}
              onChange={(event) =>
                fieldChanged(event, 'cpfcnpj', event.target.value)
              }
              disabled={!masterUser?.permission?.users?.write}
            />
          </FloatingLabel>
        </Col>

        <Col md>
          <FloatingLabel controlId="floatingInput" label="Empresa" className="mb-3">
            <Form.Control
              type="text"
              name="company"
              placeholder="Empresa"
              value={user?.company || ''}
              onChange={(event) =>
                fieldChanged(event, 'company', event.target.value)
              }
              disabled={!masterUser?.permission?.users?.write}
            />
          </FloatingLabel>
        </Col>
      </Row>

      <Row>
        <Col md>
          <FloatingLabel controlId="floatingInput" label="Fone" className="mb-0">
            <Form.Control
              type="Fone"
              name="fone"
              placeholder="Fone"
              value={user?.fone || ''}
              onChange={(event) =>
                phoneChanged(event, 'fone', event.target.value)
              }
              disabled={!masterUser?.permission?.users?.write}
            />
          </FloatingLabel>
        </Col>

        <Col md>
          <FloatingLabel controlId="floatingInput" label="Cadastrado em" className="mb-0">
            <Form.Control
              type="text"
              name="Cadastrado em"
              placeholder="Cadastrado em"
              value={dateformat(user?.createdAt, true)}
              disabled
            />
          </FloatingLabel>
        </Col>

        <Col md>
          <FloatingLabel controlId="floatingInput" label="Último login" className="mb-0">
            <Form.Control
              type="text"
              name="Último login"
              placeholder="Último login"
              value={dateformat(user.updatedAt, true)}
              disabled
            />
          </FloatingLabel>
        </Col>
      </Row>

      <hr />

      {masterUser?.master ?
        <Row>
          <Col md>
            <h4 className='text-center'>Autorizações Site API</h4>
            <Table striped bordered hover>
              <thead>
                <tr className='text-center'>
                  <th className='col-sm-6'>Página</th>
                  <th className='col-sm-3'>Ver</th>
                  <th className='col-sm-3'>Editar</th>
                </tr>
              </thead>
              <tbody>

                <tr className='text-center'>
                  <td>
                    Master
                  </td>
                  <td colSpan={2}>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.master}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const updatePermissions = (permissions) => {
                            Object.keys(permissions).forEach((key) => {
                              if (permissions[key]?.read !== undefined) {
                                permissions[key].read = isChecked;
                              }
                              if (permissions[key]?.write !== undefined) {
                                permissions[key].write = isChecked;
                              }
                            });
                          };
                          updatePermissions(user.permission);
                          user.master = isChecked;
                          setUser({ ...user });
                        }}
                        disabled={!masterUser?.master}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Todas
                  </td>

                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={Object.values(user.permission).every(permission => permission.read)}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const updateReadPermissions = (permissions) => {
                            Object.keys(permissions).forEach((key) => {
                              if (permissions[key]?.read !== undefined) {
                                permissions[key].read = isChecked;
                              }
                            });
                          };
                          updateReadPermissions(user?.permission);
                          setUser({ ...user });
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>

                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={Object.values(user?.permission).every(permission => permission?.write)}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const updateReadPermissions = (permissions) => {
                            Object.keys(permissions).forEach((key) => {
                              if (permissions[key]?.write !== undefined) {
                                permissions[key].read = isChecked;
                                permissions[key].write = isChecked;
                              }
                            });
                          };
                          updateReadPermissions(user?.permission);
                          setUser({ ...user });
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Home
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.home.read}
                        onChange={(event) => {
                          user.permission.home.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.home.write}
                        onChange={(event) => {
                          user.permission.home.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Usuários
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.users.read}
                        onChange={(event) => {
                          user.permission.users.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.users.write}
                        onChange={(event) => {
                          user.permission.users.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Cooperativas
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.cooperatives.read}
                        onChange={(event) => {
                          user.permission.cooperatives.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.cooperatives.write}
                        onChange={(event) => {
                          user.permission.cooperatives.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Unidades
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.units.read}
                        onChange={(event) => {
                          user.permission.units.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.units.write}
                        onChange={(event) => {
                          user.permission.units.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Equipamentos
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.equipments.read}
                        onChange={(event) => {
                          user.permission.equipments.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.equipments.write}
                        onChange={(event) => {
                          user.permission.equipments.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Mapa
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.maps.read}
                        onChange={(event) => {
                          user.permission.maps.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.maps.write}
                        onChange={(event) => {
                          user.permission.maps.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Curvas
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.charts.read}
                        onChange={(event) => {
                          user.permission.charts.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.charts.write}
                        onChange={(event) => {
                          user.permission.charts.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Suporte
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.support.read}
                        onChange={(event) => {
                          user.permission.support.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.support.write}
                        onChange={(event) => {
                          user.permission.support.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Suporte Dry
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.supportdry.read}
                        onChange={(event) => {
                          user.permission.supportdry.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.supportdry.write}
                        onChange={(event) => {
                          user.permission.supportdry.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Qualidade
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.equipmentsQL.read}
                        onChange={(event) => {
                          user.permission.equipmentsQL.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.equipmentsQL.write}
                        onChange={(event) => {
                          user.permission.equipmentsQL.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Notificações
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.notifications.read}
                        onChange={(event) => {
                          user.permission.notifications.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.notifications.write}
                        onChange={(event) => {
                          user.permission.notifications.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Gera Senha
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.userspassword.read}
                        onChange={(event) => {
                          user.permission.userspassword.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.userspassword.write}
                        onChange={(event) => {
                          user.permission.userspassword.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Configurações
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.general.read}
                        onChange={(event) => {
                          user.permission.general.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permission.general.write}
                        onChange={(event) => {
                          user.permission.general.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

              </tbody>
            </Table>
          </Col>

          <Col md>
            <h4 className='text-center'>Autorizações Site SGU</h4>
            <Table striped bordered hover responsive>
              <thead>
                <tr className='text-center'>
                  <th className='col-sm-6'>Página</th>
                  <th className='col-sm-3'>Ver</th>
                  <th className='col-sm-3'>Editar</th>
                </tr>
              </thead>
              <tbody>

                <tr className='text-center'>
                  <td>
                    Todas
                  </td>

                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={Object.values(user.permissionSGU).every(permission => permission?.read)}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const updateReadPermissions = (permissions) => {
                            Object.keys(permissions).forEach((key) => {
                              if (permissions[key]?.read !== undefined) {
                                permissions[key].read = isChecked;
                              }
                            });
                          };
                          updateReadPermissions(user?.permissionSGU);
                          setUser({ ...user });
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>

                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={Object.values(user?.permissionSGU).every(permission => permission?.write)}
                        onChange={(event) => {
                          const isChecked = event.target.checked;
                          const updateReadPermissions = (permissions) => {
                            Object.keys(permissions).forEach((key) => {
                              if (permissions[key]?.write !== undefined) {
                                permissions[key].read = isChecked;
                                permissions[key].write = isChecked;
                              }
                            });
                          };
                          updateReadPermissions(user?.permissionSGU);
                          setUser({ ...user });
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>

                <tr className='text-center'>
                  <td>
                    Home
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.home.read}
                        onChange={(event) => {
                          user.permissionSGU.home.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.home.write}
                        onChange={(event) => {
                          user.permissionSGU.home.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td>
                    Unidades
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.units.read}
                        onChange={(event) => {
                          user.permissionSGU.units.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.units.write}
                        onChange={(event) => {
                          user.permissionSGU.units.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td>
                    Equipamentos
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.equipments.read}
                        onChange={(event) => {
                          user.permissionSGU.equipments.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.equipments.write}
                        onChange={(event) => {
                          user.permissionSGU.equipments.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td>
                    Restrito
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.restricted.read}
                        onChange={(event) => {
                          user.permissionSGU.restricted.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.restricted.write}
                        onChange={(event) => {
                          user.permissionSGU.restricted.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td>
                    Relatório
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.report.read}
                        onChange={(event) => {
                          user.permissionSGU.report.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.report.write}
                        onChange={(event) => {
                          user.permissionSGU.report.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td>
                    Configurações
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.settings.read}
                        onChange={(event) => {
                          user.permissionSGU.settings.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.settings.write}
                        onChange={(event) => {
                          user.permissionSGU.settings.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
                <tr className='text-center'>
                  <td>
                    Dados da Empresa
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.companyData.read}
                        onChange={(event) => {
                          user.permissionSGU.companyData.read = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                  <td>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={user.permissionSGU.companyData.write}
                        onChange={(event) => {
                          user.permissionSGU.companyData.write = event.target.checked;
                          setUser({ ...user })
                        }}
                        disabled={!masterUser?.permission?.users?.write}
                        isValid
                      />
                    </Form.Check>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        : null
      }

      <Row>
        <Col>
          <Button
            disabled={!permission?.users?.write}
            onClick={saveUser}
            variant="success"
          >
            Salvar
          </Button>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <Button
            disabled={!permission?.users?.read}
            onClick={() => {
              setValueForm(true)
              setValueFormSenhas(false)
            }}
            variant="success"
          >
            Abrir Ultimas Ativações
          </Button>
          <Button
            className='ms-2'
            disabled={!permission?.users?.read}
            onClick={() => {
              setValueForm(false)
              setValueFormSenhas(true)
            }}
            variant="success"
          >
            Exibir Senhas Geradas
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default Forms;
